import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/CoBrandingBannerDesktop' /* webpackChunkName: "CoBrandingBannerDesktop" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes(
    'CoBrandingBannerDesktop',
    UiTypes,
  ),
};

export default entry;
